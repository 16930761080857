import React, { useState } from 'react';
import './ContactGenAi.scss';

const ContactGenAi = () => {
  const [formData, setFormData] = useState({
    nome: "",
    empresa: "",
    email: "",
    phone: "",
  });
  const [popupVisible, setPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome completo é obrigatório.";
    if (!formData.phone.trim()) newErrors.phone = "Telefone é obrigatório.";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória.";
    if (!formData.email.trim()) {
      newErrors.email = "E-mail é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "E-mail inválido.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const response = await fetch("https://webhook.sellflux.app/webhook/custom/lead/4eeefaaf5f3b6fad9353e4f80a432833?name=name&email=email&phone=phone", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.nome,
          email: formData.email,
          phone: formData.phone,
          enterprise: formData.empresa,
        }),
      });

      if (response.ok) {
        setPopupVisible(true)
        setLoading(false);
      }

      setFormData({
        nome: "",
        empresa: "",
        email: "",
        phone: "",
      });
      setErrors({});
    } else {
      setLoading(false);
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <div className="ContactGenAi">
        <div className="left">
          <h1>Aumente a produtividade <br /> do seu time com<br /> <span>inteligência artificial.</span></h1>
          <p>Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.</p>
        </div>
        <div className="right">
          <div className="container-contact">
            <form className="custom-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <div
                  className={`form-group ${errors.nome ? "error" : ""}`}
                >
                  <input
                    type="text"
                    name="nome"
                    placeholder="NOME COMPLETO"
                    value={formData.nome}
                    onChange={handleChange}
                  />
                  {errors.nome && <span className="error">{errors.nome}</span>}
                </div>

                <div
                  className={`form-group ${errors.phone ? "error" : ""}`}
                >
                  <input
                    type="phone"
                    name="phone"
                    placeholder="TELEFONE"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && <span className="error">{errors.phone}</span>}
                </div>

                <div
                  className={`form-group ${errors.email ? "error" : ""}`}
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="SEU E-MAIL COMERCIAL"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>

                <div
                  className={`form-group ${errors.empresa ? "error" : ""}`}
                >
                  <input
                    type="text"
                    name="empresa"
                    placeholder="EMPRESA"
                    value={formData.empresa}
                    onChange={handleChange}
                  />
                  {errors.empresa && <span className="error">{errors.empresa}</span>}
                </div>
              </div>

              <div className="form-row">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn-enviar"
                >
                  {loading ? "ENVIANDO..." : "INSCREVA-SE AGORA"}
                </button>
              </div>

              <div className="text-container">
                <p>INSCREVA-SE E GANHE <br /> UMA CONVERSA <span>GRATUITA</span> <br /> COM O NOSSO TIME.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Popup de sucesso */}
      {popupVisible && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Mensagem enviada com sucesso!</h3>
            <button onClick={closePopup}>Fechar</button>
          </div>
        </div>
      )}
    </>
  )
}

export default ContactGenAi

import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import impulsoImage from '../../assets/images/cases/all/impulso.webp';
import ledImage from '../../assets/images/cases/all/led.webp';
import imageOne from '../../assets/images/consultancy/image-1.png';
import imageTwo from '../../assets/images/consultancy/image-2.png';
import imageThree from '../../assets/images/consultancy/image-3.png';
import imageSection from '../../assets/images/consultancy/image-section.png';
import ContactHome from '../../components/ui/ContactHome/ContactHome';
import useIsDesktop from '../../hooks/useIsDesktop';
import './Consultancy.scss';

const Consultancy = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  return (
    <>
      <div className='Consultancy'>
        <div className="container-consultancy">
          <motion.h1
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            transforme a cultura<br /> e engaje sua comunidade<br /> com as <span>pessoas no centro.</span>
          </motion.h1>

          <motion.div
            className="header-consultancy"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <div className="text-container">
              <p> Somos uma escola que ensina tecnologia e também somos uma consultoria de inovação que trabalha com o mercado corporativo para construir soluções para os nossos tempos. Oferecemos uma abordagem única que conjuga pensamento lógico, digital, ágil e humano para transformar a maneira como empresas operam e se relacionam com seus clientes. <br /><br /><br /> Apoiamos organizações que desenvolvem projetos junto a comunidades vulneráveis com metodologias que garantem empatia, colaboração e impacto social.</p>
            </div>
            <motion.img 
              src={imageSection} 
              alt="Imagem de alunos" 
              initial={isDesktop ? { opacity: 0, y: 50 } : {}}
              whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
              transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
              viewport={isDesktop ? { once: true } : {}}
            />
          </motion.div>

          <motion.div
            className="section-consultancy"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <div className="steps-texts">
              <div className="step-text">
                <p>METODOLOGIA<br /> PRÓPRIA, BASEADA<br /> EM <span>9 PASSOS</span><br /> DE TRANSFORMAÇÃO<br /> DIGITAL.</p>
              </div>
              <div className="step-text">
                <p>EXPERIÊNCIA EM<br />  <span>PROJETOS B2B,</span><br />  SOCIAIS E<br />  GOVERNAMENTAIS.</p>
              </div>
              <div className="step-text">
                <p>ATUAÇÃO EM<br />  EMPRESAS<br />  COMO <span>FACEBOOK,<br /> BAYER, IBM<br /> E HEINEKEN</span></p>
              </div>
            </div>
            <div className="steps-images">
              <div className="step-image">
                <motion.img
                  src={imageOne}
                  alt="Imagem de conscientização"
                  initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                  whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
                <p>Diagnóstico inicial<br /> e avaliação<br /> de cultura digital.</p>
              </div>
              <div className="step-image">
                <motion.img
                  src={imageTwo}
                  alt="Imagem de conhecimento"
                  initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                  whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
                <p>Laboratório ágil para<br /> desenvolver as habilidades<br /> necessárias.</p>
              </div>
              <div className="step-image">
                <motion.img
                  src={imageThree}
                  alt="Imagem de consolidação"
                  initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                  whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
                <p>Inception e Design Sprint<br /> para criar estratégias práticas.</p>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="cases-options"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <p className="title-like">OS CASES DA ESCOLA</p>
            <div className="cards">
              <motion.div
                className="card"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
                onClick={() => {
                  navigate(`/team-buildings/led`)
                }}
              >
                <img src={ledImage} alt="Imagem da Globo LED" />
                <div className="content-card">
                  <p className="title">GLOBO | Desafio LED - Me Dá Uma Luz Aí</p>
                  <p className="description">Nosso programa de empreendedorismo social</p>
                </div>
              </motion.div>
              <motion.div
                className="card"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
                onClick={() => {
                  navigate(`/team-buildings/led`)
                }}
              >
                <img src={impulsoImage} alt="Imagem da Impulso" />
                <div className="content-card">
                  <p className="title">A&M | Programa IMPULSO</p>
                  <p className="description">Educação empreendedora e impacto social</p>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
      <ContactHome witdh areaSite={'Página de consultoria'} />
    </>
  );
};

export default Consultancy;

import { motion } from "framer-motion";
import React from 'react';
import { useNavigate } from "react-router-dom";
import bayer from '../../assets/images/cases/all/bayer.webp';
import belts from '../../assets/images/cases/all/belts.webp';
import heineken from '../../assets/images/cases/all/heineken.webp';
import impulso from '../../assets/images/cases/all/impulso.webp';
import led from '../../assets/images/cases/all/led.webp';
import text from '../../assets/images/mastertechwho/text.png';
import ContactHome from '../../components/ui/ContactHome/ContactHome';
import './CaseList.scss';
import useIsDesktop from '../../hooks/useIsDesktop';

const CaseList = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const dataCases = [
    {
      title: "A&M | Belts",
      description: "Six sigma, design thinking e mindset ágil",
      image: belts,
      id: 'am'
    },
    {
      title: "HEINEKEN | Transformação",
      description: "digital Cultura de dados e data driven mindset",
      image: heineken,
      id: 'heineken'
    },
    {
      title: "GLOBO | Desafio LED - Me Dá Uma Luz Aí",
      description: "Nosso programa de empreendedorismo social",
      image: led,
      id: 'led'
    },
    {
      title: "A&M | Programa IMPULSO",
      description: "Educação empreendedora e impacto social",
      image: impulso,
      id: 'impulso'
    },
    {
      title: "BAYER | Inception",
      description: "Co-criação e inovação",
      image: bayer,
      id: 'bayer'
    },
  ];

  return (
    <>
      <div className='CaseList'>
        <div className="container-caselist">
          <div className="header-caselist">
            <motion.p
              className="title"
              initial={isDesktop ? { opacity: 0, y: 50 } : {}}
              whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
              viewport={isDesktop ? { once: true, margin: "-100px" } : {}}
              transition={isDesktop ? { duration: 1 } : {}}
            >
              os cases <br /> da escola.
              <img src={text} alt="Tamo aí na agilidade" />
            </motion.p>
          </div>

          <div className="content-cases">
            <motion.p
              className="title"
              initial={isDesktop ? { opacity: 0, y: 50 } : {}}
              whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
              viewport={isDesktop ? { once: true, margin: "-20px" } : {}}
              transition={isDesktop ? { duration: 1 } : {}}
            >
              CASES DE TRANSFORMAÇÃO DIGITAL
            </motion.p>

            <div className="cases">
              {dataCases.map((item) => (
                <motion.div
                  key={item.id}
                  initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                  whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                  viewport={isDesktop ? { once: true, margin: "-100px" } : {}}
                  transition={isDesktop ? { duration: 2 } : {}}
                  onClick={() => navigate(`/case/${item.id}`)}
                >
                  <div className="case">
                    <img src={item.image} alt={item.title} />
                    <div className="content-card">
                      <p className="title">{item.title}</p>
                      <p className="description">{item.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ContactHome witdh areaSite={"Página de contato"}/>
    </>
  );
};

export default CaseList;

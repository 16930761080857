import { motion } from 'framer-motion';
import React from 'react';
import arrowButton from '../../assets/images/banner/arrow-bottom.png';
import imageRight from "../../assets/images/mtalks/logo-mtalks.webp";
import imageLeft from "../../assets/images/mtalks/section-image.webp";
import ContactHome from '../../components/ui/ContactHome/ContactHome';
import { replaceWithLineBreaks } from '../../utils/formating';
import './MTalks.scss';
import { talksData } from './talksData';
import useIsDesktop from '../../hooks/useIsDesktop';

const MTalks = () => {
  const isDesktop = useIsDesktop();

  return (
    <>
      <div className='MTalks'>
        <div className="container-mtalks">
          <motion.div
            className="header-mtalks"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <div>
              <h1>M/Talks</h1>
              <h2>As palestras com <br /> <span>Camila Achutti.</span></h2>
            </div>
            <motion.div
              className="circular-text-container"
              initial={isDesktop ? { y: "200px", opacity: 0 } : {}}
              animate={isDesktop ? { y: 0, opacity: 1 } : {}}
              exit={isDesktop ? { y: "20vw", opacity: 0 } : {}}
              transition={isDesktop ? { duration: 1.3, ease: "easeInOut" } : {}}
              viewport={isDesktop ? { once: true } : {}}
            >
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="circle-svg">
                <defs>
                  <path
                    id="circlePath"
                    d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                  />
                </defs>
                <text className="circle-text">
                  <textPath href="#circlePath" textAnchor="middle" startOffset="50%">
                    conhecimento em movimento
                  </textPath>
                </text>
              </svg>
              <img src={arrowButton} alt="arrowButton" className='arrow' />
            </motion.div>
          </motion.div>
          <motion.div
            className="section-mtalks"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <div className="left">
              <p>
                A Mastertech conta com um catálogo de talks que vão dos conteúdos explorados em nossos cursos até questões comportamentais emergentes no cenário corporativo, incluindo discussões sobre tendências tecnológicas e a sensibilização para tópicos de relevo, como as pautas EGS e a exclusão digital. <br /><br /><br /> Todas as nossas palestras mesclam rigor científico, consciência contextual e propósitos práticos, e são cuidadosamente customizadas para gerar engajamento, abrindo janelas para o aprendizado de novos conhecimentos por variados públicos.
              </p>
              <img src={imageLeft} alt="Camila Achutti utilizando microfone" />
            </div>
            <div className="right">
              <img src={imageRight} alt="Camila Achutti" />
              <p>
                Somando-se ao nosso raio de alcance temático e metodologia verificada, também contamos com a expertise de Camila Achutti, fundadora e CEO Mastertech, que além de empreendedora, é idealizadora da ONG SOMAS, conselheira de empresas e sociedades anônimas, delegada do W20, sub-grupo do G20, doutoranda em Ciências da Computação pela USP, precursora das influencers digitais com o blog “Mulheres na Computação”, vencedora dos prêmios Women of Vision (2015) e Forbes Under 30 (2017), personagem do Maurício de Sousa e dos documentários “Inovar é um parto” e “Cibernéticas”, narradora do podcast “Histórias de Ninar Garotas Rebeldes” e autora do livro “Razão nada poética”.<br /><br /> Vê-se, portanto, que temos propriedade não só para criar exposições personalizadas, como para transitar por searas como:
              </p>
            </div>
          </motion.div>
          <motion.div
            className="talks-list"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            {talksData.map(talk => (
              <motion.div
                className="talk"
                key={talk.id}
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
              >
                <p className="title" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(talk.title) }} />
                <p className="subtitle" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(talk.subtitle) }} />
                <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(talk.description) }} />
                {talk.dot && <div className={`dot ${talk.dot}`} />}
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
      <ContactHome witdh areaSite={'Página de Palestras'} />
    </>
  );
}

export default MTalks;
